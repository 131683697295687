import { FC, ReactElement, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Bell, Gear, List } from '@phosphor-icons/react';
import { twMerge } from 'tailwind-merge';

import { Avatar } from '@shared/components/Avatar';
import { NeedPermission } from '@shared/components/NeedPermission';
import { Tooltip } from '@shared/components/Tooltip';

import { Roles } from '@modules/auth/constants/Roles';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

import { useProfile } from '@modules/profiles/hooks/useProfile';

import { FeatureFlagKeys } from '../FeatureFlag/constants/FeatureFlagKeys';
import { useHeader } from './hooks/useHeader';
import { IHeaderProps } from './types';

export const Header: FC<IHeaderProps> = ({ title }): ReactElement => {
  const { t: tRoutes } = useTranslation('routes');
  const { t: tGlobals } = useTranslation('globals');
  const { t: tNotifications } = useTranslation('notifications');

  const navigate = useNavigate();

  const { toggleSidebarOpened } = useSidebar();
  const { currentProfile } = useProfile();

  const { navigateToProfile, qtNotifications } = useHeader();

  const inMaintenanceIsEnabled = useFeatureIsOn(FeatureFlagKeys.IN_MAINTENANCE);

  // Set title to page
  useEffect(() => {
    if (title) {
      document.title = tRoutes(title);
    }
  }, [tRoutes, title]);

  // Check feature flag in-maintenance mode is enabled
  useEffect(() => {
    if (inMaintenanceIsEnabled) {
      navigate('/maintenance', { replace: true });
    }
  }, [inMaintenanceIsEnabled, navigate]);

  return (
    <header className="mb-4 flex w-full items-center gap-4 pt-3 lg:mb-8 lg:pt-8">
      <List
        data-is-desktop={isDesktop}
        size={24}
        className="text-green-500 data-[is-desktop=true]:hidden"
        onClick={toggleSidebarOpened}
      />

      <div className="ml-auto flex items-center gap-4">
        {/* {currentProfile.isMain && (isDesktop || isAndroid || (isIOS && isSafari)) && (
          <Tooltip.Root>
            <Tooltip.Trigger>
              <Link to="/subscriptions">
                <Crown
                  data-is-free={currentSubscription.isFree}
                  size={24}
                  className="text-yellow-600 data-[is-free=true]:text-green-500"
                  weight={currentSubscription.isFree ? 'regular' : 'fill'}
                />
              </Link>
            </Tooltip.Trigger>
            <Tooltip.Content
              text={tSubscriptions(currentSubscription.isFree ? 'update_your_plan_message' : 'your_plan_message')}
            />
          </Tooltip.Root>
        )} */}

        <NeedPermission permission={Roles.configurations.GET_CONFIGURATIONS}>
          <Tooltip.Root>
            <Tooltip.Trigger>
              <Link to="/configurations">
                <Gear size={24} className="text-green-500" />
              </Link>
            </Tooltip.Trigger>
            <Tooltip.Content text={tGlobals('go_to_configurations')} />
          </Tooltip.Root>
        </NeedPermission>

        <Tooltip.Root>
          <Tooltip.Trigger>
            <Link to="/notifications" className="relative">
              <Bell
                size={24}
                className={twMerge('text-green-500', qtNotifications > 0 ? 'animate-pulse' : 'animate-none')}
                weight={qtNotifications > 0 ? 'fill' : 'regular'}
              />

              {qtNotifications > 0 && (
                <div className="absolute right-[2px] top-0 h-2 w-2 animate-pulse rounded-full bg-red-500" />
              )}
            </Link>
          </Tooltip.Trigger>
          <Tooltip.Content text={tNotifications('notifications')} />
        </Tooltip.Root>
      </div>

      <Avatar.Root classNameContainer="cursor-pointer" mode="ROUNDED" size="xs" onClick={navigateToProfile}>
        <Avatar.Content url={currentProfile.avatarUrl} />
      </Avatar.Root>
    </header>
  );
};
